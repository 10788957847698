/*
 * @Author: jiang
 * @Date: 2021-08-02 15:46:57
 * @Description: 职位管理
 */
import request from '../axios'

export function getPostList() {
  return request({
    url: '/api/get/duties/list',
  })
}

/**
 * 获取我创建职位列表
 */
export function getMyCreatePostList() {
  return request({
    url: '/api/my/created/duties',
  })
}

/**
 * 获取职位列表
 */
export function getPostPage(params) {
  return request({
    url: '/api/duties',
    params,
  })
}

/**
 * 职位详情
 */
export function getPost(id) {
  return request({
    url: `/api/duties/${id}`,
  })
}

/**
 * 创建职位
 * @param { object } params
 * @param { string } params.name
 * @param { number } params.sort
 */
export function createPost(params) {
  return request({
    method: 'post',
    url: '/api/duties',
    data: params,
  })
}

/**
 * 更新职位
 */
export function updatePost(id, params) {
  return request({
    method: 'post',
    url: `/api/duties/${id}`,
    data: params,
  })
}

/**
 * 删除职位
 */
export function deletePost(id) {
  return request({
    method: 'post',
    url: `/api/duties/del/${id}`,
  })
}
