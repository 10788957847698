<template>
  <div class="regist-box">
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-width="80px"
    >
      <el-form-item label-width="0">
        <div class="logo"></div>
        <div class="title">{{ title }}</div>
      </el-form-item>
      <el-form-item
        label="所在部门"
        prop="department_id"
      >
        <b-select-dept v-model="formData.department_id"></b-select-dept>
      </el-form-item>
      <el-form-item
        label="职务"
        prop="duties_id"
      >
        <el-select v-model="formData.duties_id">
          <el-option
            v-for="item in postList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="真实姓名"
        prop="name"
      >
        <el-input
          v-model="formData.name"
          placeholder="输入用户名"
          @keyup.enter.native="onRegist"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        prop="phone"
      >
        <el-input
          v-model="formData.phone"
          placeholder="输入手机号"
          @keyup.enter.native="onRegist"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="验证码"
        prop="verify"
      >
        <div class="verify-box">
          <el-input
            v-model="formData.verify"
            placeholder="输入验证码"
            @keyup.enter.native="onRegist"
          >
          </el-input>
          <el-button
            type="primary"
            :disabled="verifyDisabled"
            @click="onGetVerify"
          >{{ verifyText }}</el-button>
        </div>
      </el-form-item>
      <el-form-item
        label="密码"
        prop="password"
      >
        <el-input
          v-model="formData.password"
          type="password"
          placeholder="输入密码"
          @keyup.enter.native="onRegist"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="确认密码"
        prop="password_confirmation"
      >
        <el-input
          v-model="formData.password_confirmation"
          type="password"
          placeholder="输入密码"
          @keyup.enter.native="onRegist"
        >
        </el-input>
      </el-form-item>
      <el-form-item style="margin-top: -15px;">
        <div class="regist-box">
          <span>已有账号？</span>
          <el-link
            type="primary"
            :underline="false"
            @click="$router.push({ name: 'login' })"
          >立即登录</el-link>
        </div>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button
          type="primary"
          block
          :loading="loading"
          @click.native.prevent="onRegist"
        >注册</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
import { userRegist, getSms } from '@/api/admin'
import { getPostList } from '@/api/admin/post'
import BSelectDept from '@/components/select/cascader/dept'

export default {
  components: {
    BSelectDept,
  },
  data() {
    const passwordValid = (rule, value, callback) => {
      if (
        this.formData.password &&
        this.formData.password_confirmation &&
        this.formData.password !== this.formData.password_confirmation
      ) {
        callback(new Error('俩次输入密码不相同'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      title: '农牧厅精准监督',
      verifyText: '获取验证码',
      verifyDisabled: false,
      verifyTimer: null,
      depTree: [],
      postList: [],
      formData: {
        name: '',
        phone: '',
        verify: '',
        department_id: null,
        duties_id: '',
        password: '',
        password_confirmation: '',
      },
      formRules: {
        name: [{ required: true, message: '必填项', trigger: 'blur' }],
        phone: [
          { required: true, message: '必填项', trigger: 'blur' },
          { validator: this.$validator.isPhone },
        ],
        verify: [{ required: true, message: '必填项', trigger: 'change' }],
        department_id: [{ required: true, message: '必填项', trigger: 'change' }],
        duties_id: [{ required: true, message: '必填项', trigger: 'change' }],
        password: [
          { required: true, message: '必填项', trigger: 'blur' },
          { validator: passwordValid },
        ],
        password_confirmation: [
          { required: true, message: '必填项', trigger: 'blur' },
          { validator: passwordValid },
        ],
      },
    }
  },
  created() {
    this.fetchPostList()
  },
  methods: {
    fetchPostList() {
      getPostList().then(res => {
        this.postList = res
      })
    },
    fetchRegist() {
      this.loading = true
      userRegist(this.formData)
        .then(res => {
          this.$message.success('注册成功')
          this.$router.replace({ name: 'login' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRegist() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // console.log(this.formData)
          this.fetchRegist()
        }
      })
    },
    onGetVerify() {
      this.$refs.form.validateField('phone', valid => {
        if (!valid) {
          this.verifyDisabled = true
          getSms(this.formData.phone, 'register_verify')
            .then(res => {
              let count = 10
              this.verifyText = `重新获取 ${count}s`
              this.verifyTimer = setInterval(() => {
                if (count < 1) {
                  this.verifyText = '获取验证码'
                  this.verifyDisabled = false
                  clearInterval(this.verifyTimer)
                  this.verifyTimer = null
                } else {
                  count--
                  this.verifyText = `重新获取 ${count}s`
                }
              }, 1000)

              this.$once('hook:beforeDestroy', () => {
                if (this.verifyTimer) {
                  clearInterval(this.verifyTimer)
                  this.verifyTimer = null
                }
              })
            })
            .catch(() => {
              this.verifyDisabled = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.regist-box {
  .logo {
    width: 120px;
    height: 80px;
    margin: 0 auto;
    background: url('~@/assets/images/logo.png') center / contain no-repeat;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
    text-align: center;
  }

  /deep/ {
    .el-input__inner,
    .vue-treeselect__control {
      border: none;
      border-bottom: 1px solid #dcdfe6;
      border-radius: 0;
    }
  }

  .regist-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
  }

  .verify-box {
    display: flex;

    .el-button {
      width: 160px;
    }
  }
}
</style>
